export const EMAIL_REGEX = /[^@\s]+@[^@\s]+\.[^.@\s]+/;
export const PHONENUMBER_REGEX = /\+?[0-9\(\)\s\-x#]+/;
export const USERNAME_REGEX = new RegExp(
  `^(?:${EMAIL_REGEX.source})|(?:${PHONENUMBER_REGEX.source})$`,
  'u',
);
export const NAME_REGEX = /^[^<>\[\]\(\)\{\};:\|\/\x22]{1,30}$/;

export function isValidEmail(email: string): boolean {
  return new RegExp('^(?:' + EMAIL_REGEX.source + ')$', 'u').test(email);
}

export const RANGE_SEPARATOR = '–';

export function parseRange(range: string): [number, number] {
  const [start, end] = range.split(RANGE_SEPARATOR).map((s) => parseInt(s.trim(), 10));
  return [start, end];
}

export function formatRange(start: number, end: number): string {
  return `${start}${RANGE_SEPARATOR}${end}`;
}
