import { useIsLoggedIn } from 'components/templates/AuthenticationProvider/AuthenticationProvider';
import usePageTracker from 'hooks/usePageTracker';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactMarkdown from 'react-markdown';
import { FetchPolicy, graphql, useMutation } from 'react-relay';
import { useLazyLoadQuery } from 'react-relay/hooks';
import rehypeRaw from 'rehype-raw';
import { Pluggable } from 'unified';
import { ThirdPartyTrackingProviderCookieConsentMutation } from './__generated__/ThirdPartyTrackingProviderCookieConsentMutation.graphql';
import { ThirdPartyTrackingProviderCookieConsentOptionsQuery } from './__generated__/ThirdPartyTrackingProviderCookieConsentOptionsQuery.graphql';
import { ThirdPartyTrackingProviderQuery } from './__generated__/ThirdPartyTrackingProviderQuery.graphql';
import TrackingScripts from './TrackingScripts';
import useCookieConsentStatus, { ConsentStatus } from './useCookieConsentStatus';

const LazyCookieDialog = dynamic(() => import('components/molecules/CookieBanner/CookieBanner'));

const cookieConsentOptionsQuery = graphql`
  query ThirdPartyTrackingProviderCookieConsentOptionsQuery {
    cookies {
      edges {
        node {
          id
          consent {
            consented
          }
        }
      }
    }
  }
`;

const cookieConsentMutation = graphql`
  mutation ThirdPartyTrackingProviderCookieConsentMutation($input: [CookieConsentInput!]!) {
    updateUserConsent(input: $input) {
      edges {
        node {
          id
          consent {
            consented
          }
        }
      }
    }
  }
`;

export type Props = PropsWithChildren<{}>;

const TriggerTrackingContext = createContext<Dispatch<SetStateAction<boolean>>>(() => {});

export default function ThirdPartyTrackingProvider({ children }: Props) {
  usePageTracker();
  const [trackingTriggered, setTrackingTriggered] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const [consentStatus, setConsentStatus] = useCookieConsentStatus();

  useEffect(() => {
    setShowCookieBanner(consentStatus === ConsentStatus.UNKNOWN);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      '3rd_party_marking_optimization_consent':
        consentStatus === ConsentStatus.CONSENTED ? 'true' : 'false',
    });
  }, [consentStatus]);

  return (
    <TriggerTrackingContext.Provider value={setTrackingTriggered}>
      <TrackingScripts consent={consentStatus === ConsentStatus.CONSENTED} />
      {children}
      {trackingTriggered && (
        <React.Suspense fallback={''}>
          <BarcCodeFragmentHandler consentStatus={consentStatus} />
        </React.Suspense>
      )}
      {showCookieBanner && <LazyCookieDialog onConsentStatusChanged={setConsentStatus} />}
      <React.Suspense>
        <BarcCookieSyncOnRegister consentStatus={consentStatus} />
      </React.Suspense>
    </TriggerTrackingContext.Provider>
  );
}

export function useTrackingTrigger(): () => void {
  const setTrackingTriggered = useContext(TriggerTrackingContext);
  return () => setTrackingTriggered(true);
}

const trackingQuery = graphql`
  query ThirdPartyTrackingProviderQuery {
    cookies {
      edges {
        node {
          id
          setter
          unsetter
          type
        }
      }
    }
  }
`;

type BarcCodeFragmentHandlerProps = { consentStatus: ConsentStatus };

function BarcCodeFragmentHandler({ consentStatus }: BarcCodeFragmentHandlerProps) {
  const data = useLazyLoadQuery<ThirdPartyTrackingProviderQuery>(trackingQuery, {});
  if (data.cookies?.edges && Array.isArray(data.cookies.edges)) {
    return (
      <>
        {data.cookies.edges.map(({ node }) => {
          const { id, setter, unsetter, type } = node;

          /**
           * We use the Markdown component with the rehypeRaw plugin to convert the provided HTML
           * fragments to JSX, this avoids limitations of innerHTML (which doesn't allow scripts to
           * execute), we also use the Script component to ensure that the scripts are executed as
           * simply adding them to the body in place doesn't seem to work.
           */
          return (
            <ReactMarkdown
              key={id}
              rehypePlugins={[rehypeRaw as unknown as Pluggable<any[]>]}
              components={{
                script: ({ node, ...props }) => {
                  return <Script {...props} />;
                },
              }}
            >
              {type === 'ESSENTIAL' ||
              (type === 'THIRDPARTY' && consentStatus === ConsentStatus.CONSENTED)
                ? setter
                : unsetter}
            </ReactMarkdown>
          );
        })}
      </>
    );
  }
  return null;
}

type BarcCookieSyncOnRegisterProps = { consentStatus: ConsentStatus };

/**
 * Behavioural and Advertising Research Cookies (BARC) cookie sync on registration.
 */
function BarcCookieSyncOnRegister({ consentStatus }: BarcCookieSyncOnRegisterProps) {
  const router = useRouter();
  const isLoggedIn = useIsLoggedIn();
  const isDOI = useRef<boolean>(false);

  // This is a work-around to prevent the error:
  // "This Suspense boundary received an update before it finished hydrating"
  // which seems to be caused by the early call to useLazyLoadQuery.
  // The initial fetchPolicy of store only will prevent suspense from being used on the first render.
  const [fetchPolicy, setFetchPolicy] = useState<FetchPolicy>('store-only');
  useEffect(() => {
    // We only use this data if the user is logged in, so don't fetch it unless they are.
    if (isLoggedIn) setFetchPolicy('store-and-network');
  }, [isLoggedIn]);

  const cookieConsents = useLazyLoadQuery<ThirdPartyTrackingProviderCookieConsentOptionsQuery>(
    cookieConsentOptionsQuery,
    {},
    {
      fetchPolicy: fetchPolicy,
    },
  );
  const [commitCookieConsent] =
    useMutation<ThirdPartyTrackingProviderCookieConsentMutation>(cookieConsentMutation);

  useEffect(() => {
    // When the user first arrives with the DOI parameter the authentication provider will not have logged the user in yet.
    // Save state for later:
    if (router.query.doi) {
      isDOI.current = true;
    }
    // Once the user is logged in, check if they just arrived from a DOI link, if so they just registered.
    if (isDOI.current && isLoggedIn) {
      const cookieConsentIds = cookieConsents.cookies?.edges?.map((edge) => edge!.node.id) ?? [];
      commitCookieConsent({
        variables: {
          input: cookieConsentIds.map((id) => {
            return {
              cookieId: id,
              consented: consentStatus === ConsentStatus.CONSENTED,
              location: location.href,
            };
          }),
        },
      });
    }
  }, [commitCookieConsent, consentStatus, cookieConsents, isLoggedIn, router.query.doi]);

  return <></>;
}
