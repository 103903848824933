import React, { MouseEventHandler } from 'react';
import classes from './Overlay.module.scss';
import clsx from 'clsx';

export interface Props {
  onBackgroundInteraction?: () => void;
  className?: string;
  children: React.ReactNode;
}

const Overlay: React.FC<Props> = ({ onBackgroundInteraction, children, className }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = React.useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      if (onBackgroundInteraction && ref.current === e.target) {
        onBackgroundInteraction();
      }
    },
    [onBackgroundInteraction, ref],
  );

  return (
    <div
      className={clsx(classes.root, className)}
      ref={ref}
      onClick={onBackgroundInteraction && handleClickOutside}
    >
      {children}
    </div>
  );
};

export default Overlay;
