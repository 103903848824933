import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { MdCheckCircleOutline, MdClose, MdErrorOutline, MdWarningAmber } from 'react-icons/md';
import Button from '../Button/Button';
import Heading from '../Heading/Heading';
import Txt from '../Txt/Txt';
import classes from './Alert.module.scss';

export interface Props {
  title: ReactNode;
  subtitle?: ReactNode;
  type: 'error' | 'warning' | 'info' | 'success';
  onClose?: () => void;
  className?: string;
}

const Alert: React.FC<Props> = ({ title, subtitle, type, onClose, className }) => {
  const Icon = () => {
    switch (type) {
      case 'error':
        return <MdErrorOutline />;
      case 'warning':
        return <MdWarningAmber />;
      case 'info':
        return <MdErrorOutline />;
      case 'success':
        return <MdCheckCircleOutline />;
    }
  };

  return (
    <div className={clsx(classes.root, classes[`type-${type}`], className)}>
      <div className={clsx(classes.icon)}>
        <Icon />
      </div>
      <div className={clsx(classes.message)}>
        <Heading level={4} className={clsx(classes.title)}>
          {title}
        </Heading>
        <Txt component={'p'} className={clsx(classes.subtitle)}>
          {subtitle}
        </Txt>
      </div>
      {onClose && (
        <Button className={clsx(classes.close)} onClick={() => onClose()}>
          <MdClose />
        </Button>
      )}
    </div>
  );
};

export default Alert;
