import Page, { PageOptions } from 'components/templates/Page/Page';
import { UserConfig } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticProps } from 'next/types';
import React from 'react';
import nextI18NextConfig from '../../next-i18next.config.js';

export type PageProps = {
  props: {
    namespace: string;
    _nextI18Next?:
      | {
          initialI18nStore: any;
          initialLocale: string;
          ns: string[];
          userConfig: UserConfig | null;
        }
      | undefined;
  };
};

export function buildPage<T>(Component: React.ComponentType<T>, options: PageOptions) {
  return function BuildPage(props: T & { namespace?: string }) {
    return (
      <Page namespace={props.namespace} {...options}>
        <Component {...props} />
      </Page>
    );
  };
}

interface StaticPageOptions {
  withoutHeader?: boolean;
  withoutFooter?: boolean;
}
export function buildStaticProps(
  namespace: string,
  options: StaticPageOptions = {},
): GetStaticProps {
  return async (context) => {
    const translations = await serverSideTranslations(
      context.locale || context.defaultLocale || 'en',
      ['common', 'cookies', namespace],
      nextI18NextConfig as any,
      context.locale && /^[a-z]{2}-[A-Z]{2}$/.test(context.locale)
        ? [context.locale.split('-')[0]]
        : [],
    );
    return {
      props: {
        ...(nextI18NextConfig.saveMissing ? {} : translations),
        ...options,
        namespace,
      },
    };
  };
}
