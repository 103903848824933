import clsx from 'clsx';
import LoadingIndicator from 'components/atoms/LoadingIndicator/LoadingIndicator';
import classes from './LoadingScreen.module.scss';

export interface Props {
  className?: string;
}

export default function LoadingScreen({ className }: Props) {
  return (
    <div className={clsx(classes.root, className)}>
      <LoadingIndicator width={100} />
    </div>
  );
}
