import React from 'react';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import Overlay from '../Overlay/Overlay';

export interface Props {
  className?: string;
}

const LoadingOverlay: React.FC<Props> = ({}: Props) => {
  return (
    <Overlay>
      <LoadingIndicator />
    </Overlay>
  );
};

export default LoadingOverlay;
