import clsx from 'clsx';
import Logo from 'components/assets/lifepoints-logo.svg';
import { ReactElement, ReactNode } from 'react';
import A from '../A/A';
import Heading from '../Heading/Heading';
import Trustpilot from '../Trustpilot/Trustpilot';
import Txt from '../Txt/Txt';
import classes from './RegistrationCard.module.scss';

export interface AdditionalInfo {
  title: string;
  subtitle: string | ReactNode;
  image: ReactNode;
}
export type Props = {
  title: string;
  withReportProblem?: boolean;
  className?: string;
  narrowContainer?: boolean;
  children: ReactNode;
  menu?: ReactNode;
  progressIndicator?: ReactNode;
  additionalInfo?: AdditionalInfo;
  stepTitleClassName?: string;
};

export default function RegistrationCard({
  className,
  children,
  title,
  additionalInfo,
  menu,
  withReportProblem,
  progressIndicator,
  stepTitleClassName,
  narrowContainer = false,
}: Props): ReactElement {
  return (
    <div>
      <div className={clsx(classes.root, className)}>
        <div className={classes.background} />
        <div className={classes.menuWrapper}>
          <div className={classes.header}>
            <Logo id="logo" width={150} height={50} />
            {menu && <div className={classes.menu}>{menu}</div>}
          </div>
          <div
            className={clsx(
              classes.card,
              additionalInfo && classes.withAdditionalData,
              className,
              narrowContainer && classes.narrowContainer,
            )}
          >
            {additionalInfo && (
              <div className={clsx(classes.innerContainer, classes.additionalInfo)}>
                <Heading level={2}>{additionalInfo.title}</Heading>
                {additionalInfo.subtitle}
                {additionalInfo.image}
                <Trustpilot className={clsx(classes.trustpilot)} />
              </div>
            )}
            <div className={clsx(classes.innerContainer, classes.content)}>
              {progressIndicator}
              <Heading level={2} className={clsx(classes.stepTitle, stepTitleClassName)}>
                {title}
              </Heading>
              {children}
              {withReportProblem && (
                <A className={classes.reportProblem} href="/member/zendeskhelp/new">
                  <Txt>Report a problem</Txt>
                </A>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
