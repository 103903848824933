import clsx from 'clsx';
import LoadingScreen from 'components/atoms/LoadingScreen/LoadingScreen';
import PageMetaData, { MetaData } from 'components/atoms/PageMetaData/PageMetaData';
import SuspenseWithIndicator from 'components/atoms/SuspenseWithIndicator/SuspenseWithIndicator';
import ErrorBoundary from 'components/molecules/ErrorBoundary/ErrorBoundary';
import TranslationWrapper from 'components/templates/Translation/TranslationWrapper';
import useIsSsr from 'hooks/useIsSsr';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Login from 'pages/login';
import React from 'react';
import { useIsLoggedIn } from '../AuthenticationProvider/AuthenticationProvider';
import ThirdPartyTrackingProvider from '../ThirdPartyTrackingProvider/ThirdPartyTrackingProvider';
import PageContentWrapper from './PageContentWrapper';
import wrapperClasses from './PageContentWrapper.module.scss';

interface PropsWithMetaData {
  metaData: MetaData;
}

interface PropsWithoutMetaData {
  withNestedMetaData: true;
}

export type PageOptions = {
  requiresAuthentication?: boolean;
  skipSSR?: boolean;
  fullPage?: boolean;
  unlisted?: boolean;
  loggedInRedirect?: string;
  metaData?: MetaData;
  namespace?: string;
  wrapperClass?: 'finish' | 'normal';
} & (PropsWithMetaData | PropsWithoutMetaData);
export type Props = {
  children: React.ReactNode;
} & PageOptions;

function Page({
  children,
  skipSSR,
  requiresAuthentication,
  fullPage,
  unlisted,
  loggedInRedirect,
  metaData,
  namespace,
  wrapperClass = 'normal',
}: Props): JSX.Element {
  const isLoggedIn = useIsLoggedIn();
  const router = useRouter();
  const isSsr = useIsSsr();
  const { ready } = useTranslation([namespace, 'common']);

  React.useEffect(() => {
    if (isLoggedIn && loggedInRedirect) {
      router.push(loggedInRedirect, undefined, { shallow: true });
    }
  }, [isLoggedIn, router, loggedInRedirect]);

  let content = children;
  if ((requiresAuthentication || skipSSR) && isSsr) {
    // We don't wanna prerender any content if the content is not public
    content = <LoadingScreen />;
  } else if (!isLoggedIn && requiresAuthentication) {
    content = (
      <TranslationWrapper namespace={'common'}>
        <Login />
      </TranslationWrapper>
    );
  }

  return ready ? (
    <>
      <PageMetaData
        metaData={metaData}
        shouldIndexPage={Boolean(!unlisted && !requiresAuthentication)}
      />
      <ErrorBoundary>
        <SuspenseWithIndicator>
          <PageContentWrapper
            withoutSpacing={fullPage}
            className={clsx(wrapperClasses[wrapperClass])}
          >
            <ThirdPartyTrackingProvider>{content}</ThirdPartyTrackingProvider>
          </PageContentWrapper>
        </SuspenseWithIndicator>
      </ErrorBoundary>
    </>
  ) : (
    // We don't want to render the page meta data if the translations are not ready yet
    <LoadingScreen />
  );
}

const MemoizedPage = React.memo<Props>(Page);

export default MemoizedPage;
