import React from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import classes from './PasswordInput.module.scss';
import TextInput, { Props } from './TextInput';

export const PASSWORD_REQUIREMENT_DESCRIPTIONS = 'Password must have at least 8 characters';
const PasswordInput = React.forwardRef<HTMLInputElement, Props>(function PasswordInput(props, ref) {
  const [showPassword, setShowPassword] = React.useState(false);

  props = { autoComplete: 'off', ...props };

  return (
    <TextInput
      ref={ref}
      {...props}
      type={showPassword ? 'text' : 'password'}
      startAdornment={
        <div onClick={() => setShowPassword(!showPassword)}>
          {!showPassword ? (
            <MdVisibility className={classes.visibilityToggleIcon} />
          ) : (
            <MdVisibilityOff className={classes.visibilityToggleIcon} />
          )}
        </div>
      }
      startAdornmentClassName={classes.visibilityToggle}
    />
  );
});
export default PasswordInput;
