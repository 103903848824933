import clsx from 'clsx';
import Button from 'components/atoms/Button/Button';
import React, { createContext, useCallback, useRef, useState } from 'react';
import classes from './Form.module.scss';
import { ButtonType, ButtonColor } from 'components/atoms/Button/Button';
import Txt from 'components/atoms/Txt/Txt';

export interface FormProps {
  buttonWrapperClassName?: string;
  className?: string;
  onSubmit?: (formData: FormData) => void;
  submitDisabled?: boolean;
  submitName: string;
  children: React.ReactNode;
  submitButtonColor?: ButtonColor;
  submitButtonType?: ButtonType;
  submitButtonClassName?: string;
  extraActionButtons?: React.ReactNode;
}

export type FormControllerValue = {
  notifyChange: (name: string, valid: boolean) => void;
};

export const FormControllerContext = createContext<FormControllerValue>({
  notifyChange: () => {},
});

const Form: React.FC<FormProps> = ({
  className,
  buttonWrapperClassName,
  onSubmit,
  submitName,
  submitDisabled,
  children,
  submitButtonColor,
  submitButtonType,
  submitButtonClassName,
  extraActionButtons,
  ...rest
}) => {
  const formElements = useRef(new Map<string, boolean>());
  const [valid, setValid] = useState<boolean>(false);

  const notifyChange = useCallback<FormControllerValue['notifyChange']>(
    (name: string, valid: boolean = false) => {
      formElements.current.set(name, valid);
      setValid((_) => {
        for (const valid of formElements.current.values()) {
          if (!valid) {
            return false;
          }
        }
        return true;
      });
    },
    [],
  );

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    onSubmit?.(new FormData(e.currentTarget));
  }

  return (
    <FormControllerContext.Provider
      value={{
        notifyChange,
      }}
    >
      <form aria-label="form" className={clsx(classes.root, className)} onSubmit={handleSubmit}>
        {children}
        <div className={clsx(classes.buttonWrapper, buttonWrapperClassName)}>
          {extraActionButtons}
          <Button
            type="submit"
            buttonSize="large"
            disabled={submitDisabled || !valid}
            className={clsx(classes.submit, submitButtonClassName)}
            color={submitButtonColor ?? 'primary'}
            buttonType={submitButtonType ?? 'secondary'}
          >
            <Txt>{submitName}</Txt>
          </Button>
        </div>
      </form>
    </FormControllerContext.Provider>
  );
};

export default Form;
