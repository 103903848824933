/**
 * @generated SignedSource<<bb7b1bb81bc0d4613c9d9bb7bb6daaf8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CookieConsentInput = {
  consented: boolean;
  cookieId: string;
  location?: string | null;
};
export type ThirdPartyTrackingProviderCookieConsentMutation$variables = {
  input: ReadonlyArray<CookieConsentInput>;
};
export type ThirdPartyTrackingProviderCookieConsentMutation$data = {
  readonly updateUserConsent: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly consent: {
          readonly consented: boolean;
        } | null;
        readonly id: string;
      };
    }>;
  } | null;
};
export type ThirdPartyTrackingProviderCookieConsentMutation = {
  response: ThirdPartyTrackingProviderCookieConsentMutation$data;
  variables: ThirdPartyTrackingProviderCookieConsentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CookieConnection",
    "kind": "LinkedField",
    "name": "updateUserConsent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CookieEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Cookie",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CookieConsent",
                "kind": "LinkedField",
                "name": "consent",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "consented",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ThirdPartyTrackingProviderCookieConsentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ThirdPartyTrackingProviderCookieConsentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3562cd34fdfea66436169951cdbd9621",
    "id": null,
    "metadata": {},
    "name": "ThirdPartyTrackingProviderCookieConsentMutation",
    "operationKind": "mutation",
    "text": "mutation ThirdPartyTrackingProviderCookieConsentMutation(\n  $input: [CookieConsentInput!]!\n) {\n  updateUserConsent(input: $input) {\n    edges {\n      node {\n        id\n        consent {\n          consented\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8781f8a665d804d9e3c8b189f6eb80b7";

export default node;
