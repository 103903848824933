import Button from 'components/atoms/Button/Button';
import Heading from 'components/atoms/Heading/Heading';
import Image from 'components/atoms/Image/Image';
import Txt from 'components/atoms/Txt/Txt';
import TranslationWrapper from 'components/templates/Translation/TranslationWrapper';
import React from 'react';
import classes from './ErrorPage.module.scss';

export interface Props {
  statusCode?: number;
  errorMessage?: string;
}

const ErrorPage: React.FC<Props> = ({ statusCode, errorMessage }) => {
  return (
    <TranslationWrapper namespace="common">
      <article className={classes.root}>
        <div className={classes.inner}>
          {statusCode === 403 && <AccessDenied />}
          {statusCode === 404 && <NotFound />}
          {statusCode !== 403 && statusCode !== 404 && <Maintenance errorMessage={errorMessage} />}
        </div>
      </article>
    </TranslationWrapper>
  );
};
export default ErrorPage;

function NotFound() {
  return (
    <>
      <Heading level={1} className={classes.heading}>
        Page not found
      </Heading>
      <Image src="/assets/404.svg" alt="" width={520} height={400} />
      <Txt size="large">
        We can’t seem to find the page you’re looking for. Let’s find a better place for you to go.
      </Txt>
      <Button component="a" href="/" buttonSize="large">
        <Txt>Return Home</Txt>
      </Button>
    </>
  );
}

function AccessDenied() {
  return (
    <>
      <Heading level={1} className={classes.heading}>
        Content unavailable
      </Heading>
      <Image
        src="/assets/403.svg"
        alt="Police person with pink stop sign and roadblocks icon"
        width={520}
        height={400}
      />
      <Txt size="large">Sorry! This content is not available where you are.</Txt>
      <Button component="a" href="/" buttonSize="large">
        <Txt>Return Home</Txt>
      </Button>
    </>
  );
}

function Maintenance({ errorMessage }: { errorMessage?: string }) {
  return (
    <>
      <Heading level={1} className={classes.heading}>
        We’ll be back soon!
      </Heading>
      <Image src="/assets/downtime.gif" alt="" width={520} height={400} />
      <Txt size="large" skipTranslation={!!errorMessage}>
        {errorMessage ||
          `We’re currently performing scheduled maintenance to improve the LifePoints website. If you
        want more LifePoints fun, check our social media pages below; otherwise, we’ll be back
        online shortly!`}
      </Txt>
      <Button component="a" href="/" buttonSize="large">
        <Txt>Return Home</Txt>
      </Button>
    </>
  );
}
