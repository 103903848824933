import useIsSsr from 'hooks/useIsSsr';
import React from 'react';
import LoadingScreen from '../LoadingScreen/LoadingScreen';

export interface Props {
  className?: string;
  withoutSSR?: boolean;
  force?: boolean;
  fill?: boolean;
  children: React.ReactNode;
  fallback?: React.ReactNode;
}
export default function SuspenseWithIndicator({
  children,
  fallback,
  className,
  withoutSSR,
  force,
}: Props) {
  const isSsr = useIsSsr();
  const defaultedFallback = fallback || <LoadingScreen className={className} />;
  if (force || (withoutSSR && isSsr)) {
    return <>{defaultedFallback}</>;
  }
  return <React.Suspense fallback={defaultedFallback}>{children}</React.Suspense>;
}
