import LoadingScreen from 'components/atoms/LoadingScreen/LoadingScreen';
import LoginForm from 'components/organisms/LoginForm/LoginForm';
import { DASHBOARD } from 'components/pages/pages';
import { useIsLoggedIn } from 'components/templates/AuthenticationProvider/AuthenticationProvider';
import useIsSsr from 'hooks/useIsSsr';
import useQueryStringParams from 'hooks/useQueryStringParams';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React from 'react';
import { FetchPolicy, graphql } from 'react-relay';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { LoginQuery } from './__generated__/LoginQuery.graphql';

export interface Props {
  shouldSkipRedirect?: boolean;
}

const loginQuery = graphql`
  query LoginQuery {
    profilerUrl
  }
`;

const Login: React.FC<Props> = ({ shouldSkipRedirect }) => {
  /*
   * Fetch policy is set to store-only to prevent the query from being sent to the server until we
   * have established the user is logged in (the query requires an authenticated user).
   */
  const [loginQueryFetchPolicy, setLoginQueryFetchPolicy] =
    React.useState<FetchPolicy>('store-only');
  const data = useLazyLoadQuery<LoginQuery>(
    loginQuery,
    {},
    {
      fetchPolicy: loginQueryFetchPolicy,
    },
  );
  const router = useRouter();
  // Bypass redirect by using showLogin=true in query string
  const { showLogin, otp, doi, error } = useQueryStringParams();
  const isLoggedIn = useIsLoggedIn();
  const isDev = process.env.NODE_ENV === 'development';
  const isSsr = useIsSsr();

  if (!isLoggedIn) {
    // Safeguard to remove logged_in cookie to prevent redirect loop.
    const cookieName = process.env.NEXT_PUBLIC_SHARED_COOKIE || 'prod_logged_in';
    Cookies.remove(cookieName, { domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN });
  }

  React.useEffect(() => {
    if (isLoggedIn) {
      setLoginQueryFetchPolicy('store-and-network');
      if (doi === 'true') {
        if (typeof data.profilerUrl === 'string') {
          location.href = data.profilerUrl;
        } else if (data.profilerUrl === null) {
          // We check explicitly for null, as undefined is also possible but means the value hasn't been loaded yet.
          router.push(DASHBOARD.path, undefined, { shallow: true });
        }
      } else {
        router.push(DASHBOARD.path, undefined, { shallow: true });
      }
    }
  }, [data.profilerUrl, doi, error, isLoggedIn, router]);

  return !router.isReady || isLoggedIn || otp ? <LoadingScreen /> : <LoginForm />;
};

export default Login;
