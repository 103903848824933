import clsx from 'clsx';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useRef } from 'react';

export type Props = {
  className?: string;
};

// @see https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-widget-to-a-single-page-application#add-a-trustbox-widget-with-a-react-application-1
const TrustBox = ({ className }: Props) => {
  const ref = useRef(null);
  const { locale } = useRouter();

  React.useEffect(() => {
    if (window.Trustpilot && ref.current) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <>
      <Script
        strategy="afterInteractive"
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      />
      <div
        ref={ref}
        className={clsx('trustpilot-widget', className)}
        data-locale={locale || 'en-US'}
        data-template-id="5613c9cde69ddc09340c6beb"
        data-businessunit-id="5c76c26eaf0021000143b849"
        data-style-height="100px"
        data-style-width="100%"
        data-theme="light"
        role="img"
        aria-label="LifePoints star ratings, Trustpilot logo"
      >
        <a
          href="https://www.trustpilot.com/review/www.lifepointspanel.com"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
    </>
  );
};
export default TrustBox;
