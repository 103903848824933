import clsx from 'clsx';
import React from 'react';
import classes from './LoadingIndicator.module.scss';
import Spinner from './lifepoints-icon-animated.svg';

export interface Props {
  width?: number;
  height?: number;
  className?: string;
}

const LoadingIndicator: React.FC<Props> = ({ width = 100, height = 100, className }: Props) => {
  return (
    // <Overlay>
    <Spinner className={clsx(classes.root, className)} width={width} height={height} />
    // </Overlay>
  );
};

export default LoadingIndicator;
