import React, { useCallback, useRef } from 'react';
import { useMutation } from 'react-relay';
import { graphql } from 'react-relay';
import { useFrontEndEventTriggerMutation } from './__generated__/useFrontEndEventTriggerMutation.graphql';
import { useRouter } from 'next/router';
import { encode } from 'querystring';

const frontEndEventTriggerMutation = graphql`
  mutation useFrontEndEventTriggerMutation($input: FrontEndEventTriggeredInput!) {
    frontEndEventTriggered(input: $input)
  }
`;

export default function useFrontEndEventTrigger() {
  const { pathname, query } = useRouter();
  const pathnameRef = useRef(pathname);
  const queryRef = useRef(query);

  const [frontEndEventTriggered] = useMutation<useFrontEndEventTriggerMutation>(
    frontEndEventTriggerMutation,
  );

  const frontEndEventTrigger = useCallback(
    (eventType: string, eventDetails?: string) => {
      frontEndEventTriggered({
        variables: {
          input: {
            path: pathnameRef.current,
            query: encode(queryRef.current),
            panel: process.env.NEXT_PUBLIC_PANEL || 'lifepoints',
            eventType: eventType || 'unknown',
            eventDetails: eventDetails || null,
          },
        },
      });
    },
    [frontEndEventTriggered],
  );

  return { frontEndEventTrigger };
}
