import clsx from 'clsx';
import Txt from 'components/atoms/Txt/Txt';
import React, { useContext, useEffect, useState, useImperativeHandle, useRef } from 'react';
import { FormControllerContext } from '../../molecules/Form/Form';
import Input from './Input';
import classes from './TextInput.module.scss';

export interface TextInputProps {
  className?: string;
  inputClassName?: string;
  id: string;
  ref?: React.RefObject<HTMLInputElement>;
  startAdornment?: React.ReactNode;
  startAdornmentClassName?: string;

  overrideErrorMessage?(validity: ValidityState, value: string): string | undefined;
  customValidator?(value: string): boolean;
}

export type Props = TextInputProps & Omit<React.HTMLProps<HTMLInputElement>, 'onInvalid'>;

const TextInput = React.forwardRef<HTMLInputElement, Props>(function TextInput(
  {
    inputClassName,
    title,
    className,
    startAdornment,
    startAdornmentClassName,
    overrideErrorMessage,
    customValidator,
    hidden,
    ...inputProps
  },
  ref,
) {
  const formController = useContext(FormControllerContext);

  const inputRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

  const [error, setError] = useState<string | undefined>(undefined);
  const [isValid, setIsValid] = useState<boolean>(
    !(inputProps.required || inputProps.pattern) ||
      !(!inputProps.readOnly && !inputProps.disabled && !hidden),
  );

  useEffect(() => {
    formController.notifyChange(inputProps.id, isValid);
  }, [isValid, formController, inputProps.id]);

  useEffect(() => {
    // If text input is populated, check for validity on component load
    if (inputRef.current && !inputRef.current.validity.valueMissing) {
      setIsValid(true);
    }
  }, [inputRef]);

  function handleChange(e: React.FormEvent<HTMLInputElement>) {
    setError(undefined);
    setIsValid(true);
    if (e.currentTarget.checkValidity()) {
      if (customValidator && !customValidator(e.currentTarget.value)) {
        handleInvalid(e);
      }
    }
  }

  function handleInvalid(e: React.FormEvent<HTMLInputElement>) {
    e.currentTarget.setCustomValidity('');

    // If this element is in focus then we won't set the error message yet as the user may still be typing.
    if (e.currentTarget !== document.activeElement) {
      if (overrideErrorMessage) {
        setError(overrideErrorMessage(e.currentTarget.validity, e.currentTarget.value));
      } else {
        setError(e.currentTarget.validationMessage);
      }
    }

    setIsValid(false);
  }

  return (
    <div className={clsx(classes.root, className)} hidden={hidden}>
      <div className={classes.inputContainer}>
        {startAdornment && (
          <div className={clsx(classes.adornment, startAdornmentClassName)}>{startAdornment}</div>
        )}
        <Input
          type="text"
          onChange={(e) => {
            if (inputProps.onChange) {
              inputProps.onChange(e);
            }
            handleChange(e);
          }}
          onInvalid={handleInvalid}
          onBlur={(e) => {
            if (inputProps.onBlur) {
              inputProps.onBlur(e);
            }
            handleChange(e);
          }}
          name={inputProps.id}
          {...inputProps}
          className={clsx(classes.input, error && classes.error, inputClassName)}
          ref={inputRef}
        />
        {title && (
          <Txt
            component="label"
            className={classes.labelText}
            htmlFor={`textinput-${inputProps.id}`}
          >
            {title}
          </Txt>
        )}
      </div>
      {error && (
        <Txt className={classes.errorMessage} component="p">
          {error}
        </Txt>
      )}
    </div>
  );
});
export default TextInput;
