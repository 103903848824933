import clsx from 'clsx';
import React from 'react';
import classes from './Input.module.scss';
import { useTranslation } from 'next-i18next';
import { useNamespace } from 'components/templates/Translation/TranslationWrapper';

interface Props {
  className?: string;
  placeholder?: string;
}

const Input = React.forwardRef<HTMLInputElement, Props & React.HTMLProps<HTMLInputElement>>(
  function Input({ className, placeholder, ...rest }, ref) {
    const namespace = useNamespace();
    const { t } = useTranslation(namespace);
    return (
      <input
        ref={ref}
        placeholder={placeholder && t(placeholder)}
        className={clsx(classes.input, className)}
        {...rest}
      />
    );
  },
);
export default Input;
