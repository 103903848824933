import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { useUserJwtData } from 'components/templates/AuthenticationProvider/AuthenticationProvider';
import useOnePConfiguration from 'hooks/useOnePConfiguration';
import {
  SOCIAL_FACEBOOK_LOGIN_URL,
  SOCIAL_GOOGLE_LOGIN_URL,
  SOCIAL_APPLE_LOGIN_URL,
} from 'apiUrls';
import A from 'components/atoms/A/A';
import Txt from 'components/atoms/Txt/Txt';
import classes from './SocialSignUpButtons.module.scss';

function getCountryFromRouter({ locale }: { locale?: string }): string {
  let country = '';

  if (locale && /^[a-z]{2}-[A-Z]{2}$/.test(locale)) {
    country = locale.split('-')[1];
  } else if (locale && /^[a-z]{2}$/.test(locale)) {
    // in case of just a 2-char locale, use the language code instead
    country = locale.toUpperCase();
  }

  return country;
}

export default function SocialSignUpButtons(): ReactElement {
  const router = useRouter();
  const { country } = useUserJwtData() || { country: getCountryFromRouter(router) };
  const socialProviders: string[] = useOnePConfiguration(country, 'enabled_social_providers') || [];
  const socialLoginEnabled: boolean = socialProviders.length > 0;

  return socialLoginEnabled ? (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.signInWith)}>
        <Txt>Or sign up with</Txt>
      </div>
      <div className={clsx(classes.socialButtonsWrapper)}>
        {socialProviders.includes('facebook') && (
          <div className={clsx(classes.socialButton, classes.facebook)}>
            <A href={SOCIAL_FACEBOOK_LOGIN_URL}>facebook</A>
          </div>
        )}
        {socialProviders.includes('google') && (
          <div className={clsx(classes.socialButton, classes.google)}>
            <A href={SOCIAL_GOOGLE_LOGIN_URL}>google</A>
          </div>
        )}
        {socialProviders.includes('apple') && (
          <div className={clsx(classes.socialButton, classes.apple)}>
            <A href={SOCIAL_APPLE_LOGIN_URL}>apple</A>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
}
