import React, { ErrorInfo } from 'react';
import ErrorPage from 'components/pages/ErrorPage/ErrorPage';

export interface Props {
  children?: React.ReactNode;
  fallback?: React.ReactNode;
}

interface State {
  error?: Error;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error.message.match(/Loading chunk .* failed/)) {
      window.location.reload();
      return;
    }
    if (process.env.NODE_ENV !== 'test') {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
    this.setState({ error });
  }

  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;
    if (error) {
      return fallback === undefined ? <ErrorPage errorMessage={error.message} /> : fallback;
    }
    return children;
  }
}
