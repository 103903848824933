import React from 'react';
import { useRouter } from 'next/router';
import useIsSsr from './useIsSsr';
import useFrontEndEventTrigger from './useFrontEndEventTrigger';

export default function usePageTracker() {
  const { isReady } = useRouter();
  const isSsr = useIsSsr();
  const { frontEndEventTrigger } = useFrontEndEventTrigger();

  React.useEffect(() => {
    if (isSsr || !isReady) return;
    frontEndEventTrigger('pageview');
  }, [frontEndEventTrigger, isReady, isSsr]);
}
