/**
 * @generated SignedSource<<5a1bf62b83941a9ab5829d32633df713>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FrontEndEventTriggeredInput = {
  eventDetails?: string | null;
  eventType?: string | null;
  panel: string;
  path: string;
  query?: string | null;
};
export type useFrontEndEventTriggerMutation$variables = {
  input: FrontEndEventTriggeredInput;
};
export type useFrontEndEventTriggerMutation$data = {
  readonly frontEndEventTriggered: string | null;
};
export type useFrontEndEventTriggerMutation = {
  response: useFrontEndEventTriggerMutation$data;
  variables: useFrontEndEventTriggerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "frontEndEventTriggered",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useFrontEndEventTriggerMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useFrontEndEventTriggerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8450eaf2c0d1a6f74b1da4b793b9d934",
    "id": null,
    "metadata": {},
    "name": "useFrontEndEventTriggerMutation",
    "operationKind": "mutation",
    "text": "mutation useFrontEndEventTriggerMutation(\n  $input: FrontEndEventTriggeredInput!\n) {\n  frontEndEventTriggered(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "8e533f100e6efef5272b4e0960afb3fe";

export default node;
