import clsx from 'clsx';
import React, { ElementType } from 'react';
import classes from './PageContentWrapper.module.scss';

export type Props<T extends ElementType = 'div'> = {
  component?: T;
  children: React.ReactNode;
  className?: string;
  horizontal?: boolean;
  withoutSpacing?: boolean;
} & React.ComponentProps<T>;

const PageContentWrapper: React.FC<Props> = ({
  component,
  children,
  className,
  horizontal,
  withoutSpacing,
}) => {
  const Root = (component || 'div') as React.ElementType;
  return (
    <Root
      className={clsx(
        classes.root,
        !withoutSpacing && classes.spaced,
        horizontal && classes.horizontal,
        className,
      )}
    >
      {children}
    </Root>
  );
};

export default PageContentWrapper;
